import * as React from 'react';

const noPointerEvents = { pointerEvents: 'none' } as const;
type AnchorElement = 'a' | 'link' | 'Link';

/**
 * Element type whitelist
 *
 * @defaultValue 'button'
 *
 * @public
 */
export type AllowedElement = 'button' | AnchorElement;

export const DEFAULT_ELEMENT: AllowedElement = 'button';
export type DefaultElement = typeof DEFAULT_ELEMENT;

/**
 * Indicates the current state of the button and affects various A11Y-related attributes
 *
 * @defaultValue 'auto'
 *
 * @public
 */
export type ButtonState = 'busy' | 'disabled' | 'auto';

export interface ButtonA11yProps<As extends AllowedElement = DefaultElement> {
  /**
   * The type of element or component to render the Button as
   * @defaultValue 'button'
   */
  as?: As;
  /**
   * Indicates the current state of the button and affects various A11Y-related attributes
   * @defaultValue 'auto'
   */
  state?: ButtonState;
  /**
   * Indicates an on-off dual state, (similar to a toggle button)
   * e.g., a `follow author`, the state of which needs to be conveyed
   * to screen readers using the `aria-pressed` attribute
   *
   * @defaultValue undefined
   */
  isPressed?: boolean;
  /** The value of  button's type attribute. Ignored when `as` is not `button.` */
  type?: 'reset' | 'submit' | 'button';
  /** Inline styles to pass to the underlying component. Will be augmented for A11Y */
  style?: React.CSSProperties;
}

interface SharedA11yAttrs {
  'aria-live': 'off' | 'assertive';
  'aria-disabled'?: 'true';
  'aria-pressed'?: boolean;
  onClick?: typeof makeElementInert;
  onMouseDown?: typeof makeElementInert;
  onMouseUp?: typeof makeElementInert;
}

interface ButtonElementA11yAttrs extends SharedA11yAttrs {
  disabled?: true;
  type?: ButtonA11yProps['type'];
}

interface AnchorElementA11yAttrs extends SharedA11yAttrs {
  tabIndex?: -1;
  style?: ButtonA11yProps['style'];
}

export type ParsedButtonAttrs<As extends AllowedElement = DefaultElement> = 'button' extends As
  ? ButtonElementA11yAttrs
  : AnchorElementA11yAttrs;

function parseButtonProps<As extends 'button'>(props: ButtonA11yProps<As>): ParsedButtonAttrs<As>;
function parseButtonProps<As extends AnchorElement>(
  props: ButtonA11yProps<As>
): ParsedButtonAttrs<As>;
/**
 * Takes a button props object and return the appropriate HTML element to use
 * and the attributes to attach to the element, handling A11Y concerns
 *
 * @public
 */
function parseButtonProps<As extends AllowedElement = DefaultElement>(
  props: ButtonA11yProps<As>
): ParsedButtonAttrs<As> {
  const { as = DEFAULT_ELEMENT, state = 'auto', isPressed, ...restProps } = props;

  const sharedAttrs = restProps as ParsedButtonAttrs<As>;

  // We turn buttons into an aria-live region so that stateful buttons, e.g.
  // a loading button, declare their state in screen readers.
  // 1.
  // In order for screen readers to consistently read changes to an `aria-live`
  // area, the `aria-live` attribute must be set in the DOM _before_ changes are made.
  // We set it to `off` by default to prevent screen readers from reading content
  // of the button out loud on page load
  sharedAttrs['aria-live'] = 'off';
  if (state === 'busy' || isPressed != null) {
    // 2.
    // Make screen readers declare changes to button content when "busy"
    sharedAttrs['aria-live'] = 'assertive';
    // 3.
    // Have screen readers announce that the button cannot be used without
    // throwing away focus.
    // NOTICE: `aria-hidden` has no functional effect. You need to manualy
    // make sure to disable event handlers
    if (state === 'busy') sharedAttrs['aria-disabled'] = 'true';
  }

  if (['disabled', 'busy'].includes(state)) {
    // 4.
    // Override click handlers to prevent execution when disabled of busy
    // This isn't perfect, because since event handlers are executed in the order
    // in which they are bount, we cannot reliably disable events bound to the
    // element through `elemnt.addEventListener`.
    sharedAttrs.onClick = makeElementInert;
    sharedAttrs.onMouseDown = makeElementInert;
    sharedAttrs.onMouseUp = makeElementInert;
  }

  if (isPressed != null) sharedAttrs['aria-pressed'] = isPressed;

  if (as === 'button') {
    const attrs = sharedAttrs as ParsedButtonAttrs<'button'>;
    if (!attrs.type || !['submit', 'reset', 'button'].includes(attrs.type)) {
      attrs.type = 'button';
    }
    if (state === 'disabled') attrs.disabled = true;
    return attrs;
  }

  // We only want to set `type` on button elements, since it has
  // a completely different meaning on an anchor (it should be used to hint at
  // the linked url's MIME type), and has no built-in functionality in the browser.
  // @ts-expect-error - It's okay to delete a non existing key from an object
  delete sharedAttrs.type;

  const attrs = sharedAttrs as ParsedButtonAttrs<'a'>;

  // Remove disabled link from the document's focus tob cycle
  if (state === 'disabled') {
    attrs.tabIndex = -1;
    attrs.style = attrs.style ? { ...attrs.style, ...noPointerEvents } : noPointerEvents;
  }

  return attrs as ParsedButtonAttrs<As>;
}

export default parseButtonProps;

/* istanbul ignore next */
export function makeElementInert(evt: MouseEvent | React.SyntheticEvent) {
  evt.preventDefault();
  evt.stopPropagation();
  if ('nativeEvent' in evt) evt.nativeEvent.stopImmediatePropagation();
}
