/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { IconName } from '@haaretz/l-icon-sprite/types';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const c = s9.create({
  base: {
    '--_icn-c': 'var(--icon-color-override, var(--_icn-dflt-c))',
    '--_icn-c2': 'var(--icon--secondary-color, transparent)',
    height: '1em',
    width: '1em',
  },
  inheritVariant: { '--_icn-dflt-c': 'currenColor' },
  brandVariant: { '--_icn-dflt-c': color('primary1000') },
  brandInverseVariant: { '--_icn-dflt-c': color('primary500') },
  neutralVariant: { '--_icn-dflt-c': color('neutral1100') },
  lightNeutralVariant: { '--_icn-dflt-c': color('neutral800') },
  inverseVariant: { '--_icn-dflt-c': color('neutral150') },
  dangerVariant: { '--_icn-dflt-c': color('secondary900') },
  successVariant: { '--_icn-dflt-c': color('quaternary700') },
});

export interface IconProps extends React.SVGAttributes<SVGSVGElement> {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyIcon(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Icon
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Icon styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /** The icon to render */
  icon: IconName;
  /** The color of the icon */
  variant?:
    | 'inherit'
    | 'brand'
    | 'neutral'
    | 'lightNeutral'
    | 'inverse'
    | 'brandInverse'
    | 'danger'
    | 'success';
  /**  An A11Y label for the icon */
  a11yLabel?: string;
  /**  An A11Y description for the icon */
  a11yDescription?: string;
  /** Make the icon keyboard accessible */
  focusable?: boolean;
  /** Visually hidden text for screen readers */
  screenReaderText?: React.ReactNode;
}

const Icon = React.forwardRef<SVGSVGElement, IconProps>(function Icon(
  {
    a11yLabel,
    a11yDescription,
    variant = 'inherit',
    focusable = false,
    screenReaderText,
    icon,
    inlineStyle,
    styleExtend = [],
    ...attrs
  }: IconProps,
  ref
) {
  const hasA11yText = a11yLabel || a11yDescription;
  const descriptionId = React.useId();
  const a11yAttrs = hasA11yText
    ? {
        'aria-label': a11yLabel,
        ...(a11yDescription ? { 'aria-describedby': descriptionId } : {}),
      }
    : { 'aria-hidden': true, role: 'img' };
  const focusAttrs =
    focusable && hasA11yText ? { focusable: true } : { focusable: false, tabIndex: -1 };
  const desc = a11yDescription ? <desc id={descriptionId}>{a11yDescription}</desc> : null;
  const variantClass = `${variant}Variant` as `${typeof variant}Variant`;

  if (focusable && !(a11yDescription || a11yLabel)) {
    console.error(
      `An "${icon}" icon is set to be focusable but has no a11y label or description\n`,
      'The "focusable" attribute will not be assigned'
    );
  }

  return (
    <>
      <svg
        ref={ref}
        className={s9(c.base, c[variantClass], ...styleExtend)}
        style={inlineStyle}
        {...focusAttrs}
        {...attrs}
        {...a11yAttrs}
      >
        {desc}
        <use xlinkHref={`#icn-${icon}`} />
      </svg>
      {screenReaderText ? <VisuallyHidden>{screenReaderText}</VisuallyHidden> : null}
    </>
  );
});

export default Icon;
