'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Icon from '@haaretz/s-icon';
import * as React from 'react';
import s9 from 'style9';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

const transitionDuration = '0.25s';
const outlineTransitionDuration = '0.1s';

// `c` is short for `classNames`
const c = s9.create({
  wrapper: {
    alignItems: 'start',
    columnGap: space(2),
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',

    ...merge(
      mq({
        from: 'xl',
        value: {
          alignItems: 'center',
        },
      })
    ),

    ':hover': {
      '--bgc': color('primary500'),
    },
    // @ts-expect-error - We generally don't allow this but it's correct here
    ':has(:focus-visible)': {
      '--bgc': color('primary400'),
      '--outline-wdth': '2px',
      '--outline-offst': '3px',
    },
  },

  input: {
    pointerEvents: 'none',
    touchAction: 'none',
    height: 0,
    opacity: 0,
    position: 'absolute',
    width: 0,
  },
  iconWrapper: {
    aspectRatio: '1',
    animationDirection: 'alternate',
    animationDuration: transitionDuration,
    animationTimingFunction: 'linear',
    backgroundColor: 'var(--bgc)',
    borderRadius: radius('small'),
    flexGrow: 0,
    flexShrink: 0,
    outlineColor: color('primary1000', { opacity: 0.5 }),
    outlineOffset: 'var(--outline-offst, 0)',
    outlineStyle: 'solid',
    outlineWidth: 'var(--outline-wdth, 0)',
    overflow: 'hidden',
    position: 'relative',
    transform: 'translateY(2px)',
    transitionDuration: outlineTransitionDuration,
    transitionProperty: 'background-color,outline-offset,outline-width',
    transitionTimingFunction: 'ease-in-out',
    width: space(4),

    ...merge(
      {
        ...border({
          color: color('primary1000'),
          spacing: 1,
          style: 'solid',
          width: '1px',
          side: 'all',
        }),
      },
      mq({
        from: 'xl',
        value: {
          width: space(5),
          transform: 'none',
        },
      })
    ),

    ':after': {
      content: '""',
      position: 'absolute',
      borderRadius: radius('small'),
      height: '200%',
      width: '200%',
      top: '-50%',
      left: '-50%',
      animationDuration: transitionDuration,
      animationDirection: 'alternate',
      animationTimingFunction: 'linear',
    },
  },
  iconWrapperShow: {
    animationName: s9.keyframes({
      '0%': { backgroundColor: 'transparent' },
      '33%': { backgroundColor: color('primary1000') },
      '100%': { backgroundColor: color('primary1000') },
    }),
    ':after': {
      backgroundColor: color('primary1000'),
      content: '""',
      transform: 'translate(85%, -25%) rotate(41deg)',
      animationName: s9.keyframes({
        '0%': {
          backgroundColor: 'transparent',
          transform: 'translate(0%, 40%) rotate(41deg)',
        },
        '32%': {
          transform: 'translate(0%, 40%) rotate(41deg)',
          backgroundColor: 'transparent',
        },
        '33%': {
          backgroundColor: color('primary1000'),
          transform: 'translate(40%, 0%) rotate(41deg)',
        },
        '50%': {
          transform: 'translate(55%, 5%) rotate(41deg)',
        },
        '100%': {
          transform: 'translate(85%, -25%) rotate(41deg)',
        },
      }),
    },
  },
  iconWrapperHide: {
    animationName: s9.keyframes({
      '0%': { backgroundColor: color('primary1000') },
      '67%': { backgroundColor: color('primary1000') },
      '100%': { backgroundColor: 'transparent' },
    }),
    ':after': {
      backgroundColor: 'transparent',
      transform: 'translate(0%, 40%) rotate(41deg)',
      animationName: s9.keyframes({
        '0%': {
          backgroundColor: color('primary1000'),
          transform: 'translate(85%, -25%) rotate(41deg)',
        },
        '50%': {
          transform: 'translate(55%, 5%) rotate(41deg)',
        },
        '67%': {
          backgroundColor: color('primary1000'),
          transform: 'translate(40%, 0%) rotate(41deg)',
        },
        '68%': {
          backgroundColor: 'transparent',
          transform: 'translate(0%, 40%) rotate(41deg)',
        },
        '100%': {
          backgroundColor: 'transparent',
          transform: 'translate(0%, 40%) rotate(41deg)',
        },
      }),
    },
  },
  iconWrapperChecked: {
    backgroundColor: color('primary1000'),
  },
  icon: {
    animationDirection: 'alternate',
    animationDuration: transitionDuration,
    animationTimingFunction: 'linear',
    borderRadius: radius('small'),
    color: color('neutral100'),
    left: '50%',
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    visibility: 'hidden',
    zIndex: 0,
    ...typesetter(-2),
    ...merge({
      ...mq({ from: 'xxl', value: { ...typesetter(-4) } }),
    }),
  },
  iconChecked: {
    visibility: 'visible',
  },
  iconShow: {
    animationName: s9.keyframes({
      '0%': { visibility: 'hidden' },
      '33%': { visibility: 'hidden' },
      '34%': { visibility: 'visible' },
      '100%': { visibility: 'visible' },
    }),
  },
  iconHide: {
    animationName: s9.keyframes({
      '0%': { visibility: 'visible' },
      '67%': { visibility: 'hidden' },
      '100%': { visibility: 'hidden' },
    }),
  },
  disabled: {
    cursor: 'no-drop',
    opacity: 0.25,
  },
  label: {
    color: color('bodyText'),
    ...typesetter(-2),
    ...merge(
      fork({
        default: { ...typesetter(-2) },
        hdc: { ...typesetter(-1) },
      }),
      mq({
        from: 'xl',
        value: {
          transform: 'translateY(-0.1em)',
        },
      }),
      mq({ from: 'xxl', value: { ...typesetter(-3) } })
    ),
  },
  toggle: {
    '--width': space(8),
    height: 'calc(var(--width) / 2)',
    backgroundColor: `var(--bgc, ${color('primary300')})`,
    borderRadius: radius('pill'),
    outlineColor: color('primary1000', { opacity: 0.5 }),
    outlineOffset: 'var(--outline-offst, 0)',
    outlineStyle: 'solid',
    outlineWidth: 'var(--outline-wdth, 0)',
    position: 'relative',
    transform: 'translateY(2px)',
    transitionDuration: `${
      // background-color
      transitionDuration
    }, ${
      // outline-offset
      outlineTransitionDuration
    }, ${
      // outline-width
      outlineTransitionDuration
    }`,
    transitionProperty: 'background-color,outline-offset,outline-width',
    transitionTimingFunction: 'ease-in-out',
    width: 'var(--width)',

    ...merge(
      mq({
        from: 'xl',
        value: {
          transform: 'none',
        },
      })
    ),

    ':after': {
      aspectRatio: '1',
      content: '""',
      backgroundColor: color('primary1000'),
      position: 'absolute',
      borderRadius: radius('circle'),
      height: `calc(100% - ${space(1)})`,
      transform: fork({
        default: `translate(-${space(1)},-50%)`,
        hdc: 'translate(calc((var(--width) * -1) + 100% + 0.125rem),-50%)',
      }),
      top: '50%',
      transitionProperty: 'transform, background-color',
      transitionDuration: `${transitionDuration}, ${transitionDuration}`,
      transitionTimingFunction: 'ease-in-out',
    },
  },
  toggleChecked: {
    backgroundColor: color('primary1000'),
    ':after': {
      transform: fork({
        default: 'translate(calc((var(--width) * -1) + 100% + 0.125rem),-50%)',
        hdc: `translate(-${space(1)},-50%)`,
      }),
      backgroundColor: color('primary200'),
    },
  },
  error: {
    color: color('secondary900'),
    ...typesetter(-2),
    ...merge({}, mq({ from: 'xxl', value: { ...typesetter(-3) } })),
  },
});

//////////////
//  SHARED  //
//////////////

interface SharedProps {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyCheckbox(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Checkbox
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Checkbox styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * Same as styledExtend, but for the label of the checkbox.
   */
  labelStyleExtend?: StyleExtend;
  /**
   * The functional state of the component.
   * @defaultValue 'enabled'
   */
  state?: 'enabled' | 'disabled';
  errorText?: string;
}

///////////////
//  Wrapper  //
///////////////

interface CheckboxWrapperProps extends SharedProps, React.ComponentPropsWithoutRef<'label'> {
  /**
   * A refference to the `input` element that is rendered inside the wrapper.
   */
  inputRef: React.ForwardedRef<HTMLInputElement>;
}

export const CheckboxWrapper = React.forwardRef<HTMLLabelElement, CheckboxWrapperProps>(
  function CheckboxWrapper(
    { children, inlineStyle, inputRef, styleExtend = [], state = 'enabled', ...attrs },
    ref
  ) {
    const isDisabled = state === 'disabled';

    return (
      <label
        {...attrs}
        className={s9(c.wrapper, isDisabled && c.disabled, ...styleExtend)}
        style={inlineStyle}
        ref={ref}
        data-testid="checkbox-label"
      >
        {children}
      </label>
    );
  }
);

////////////////////////
//  Description text  //
////////////////////////

export interface CheckboxTextProps extends React.HTMLAttributes<HTMLDivElement> {
  // Make `id` required for A11Y
  id: string;
  styleExtend?: StyleExtend;
}

export const CheckboxText = React.forwardRef<HTMLDivElement, CheckboxTextProps>(
  function CheckboxText({ id, children, styleExtend = [], ...attrs }, ref) {
    return (
      <div {...attrs} className={s9(c.label, ...styleExtend)} id={id} ref={ref}>
        {children}
      </div>
    );
  }
);

////////////////
//  Checkbox  //
////////////////

type OmittedAttributes = 'children' | 'disabled' | 'type' | 'aria-labelledBy';

type InputBaseProps = React.ComponentPropsWithoutRef<'input'>;
interface InputOwnProps {
  /**
   * Set the `checked` state of the checkbox or toggle.
   * @defaultValue false
   */
  checked?: boolean;
  /**
   * An ID of the element describing the checkbox or toggle
   */
  labelledBy?: string;
}
interface InputProps extends SharedProps, Omit<InputBaseProps, OmittedAttributes>, InputOwnProps {}

interface IndeterminateProp {
  /**
   * Set the checkbox to an indeterminate state.
   */
  indeterminate?: boolean;
}

export interface FauxCheckboxProps extends InputProps, IndeterminateProp {}

export const FauxCheckbox = React.forwardRef<HTMLInputElement, FauxCheckboxProps>(
  function FauxCheckbox(
    { checked, indeterminate, labelledBy, onChange: onChangeProp, state = 'enabled', ...attrs },
    ref
  ) {
    const wasChecked = React.useRef(checked);

    const _inputRef = React.useRef<HTMLInputElement>(null);
    const inputRef = ref ?? _inputRef;

    const [isIndeterminate, setIsIndeterminate] = React.useState(indeterminate);

    const { attrsOverride, isChecked, isDisabled, onChange } = useCheckbox(
      checked,
      labelledBy,
      onChangeProp,
      state,
      setIsIndeterminate
    );

    const checkedChanged = wasChecked.current !== isChecked;
    wasChecked.current = isChecked;

    React.useEffect(() => {
      if ('current' in inputRef && inputRef.current) {
        if (indeterminate) inputRef.current.indeterminate = true;
        else inputRef.current.indeterminate = false;
      }

      setIsIndeterminate(indeterminate);
    }, [indeterminate, inputRef]);

    React.useEffect(() => {
      if ('current' in inputRef && inputRef.current && isIndeterminate != null) {
        inputRef.current.indeterminate = isIndeterminate;
      }
    }, [isIndeterminate, isChecked, inputRef]);

    return (
      <>
        <input
          {...attrs}
          {...attrsOverride}
          className={s9(c.input)}
          checked={isChecked}
          disabled={isDisabled}
          onChange={onChange}
          ref={inputRef}
        />
        <div
          aria-hidden="true"
          className={s9(
            c.iconWrapper,
            (isChecked || isIndeterminate) && c.iconWrapperChecked,
            checkedChanged && (isChecked || isIndeterminate) && c.iconWrapperShow,
            checkedChanged && !(isChecked || isIndeterminate) && c.iconWrapperHide
          )}
        >
          <Icon
            icon={isIndeterminate ? 'minus' : 'check'}
            styleExtend={[
              c.icon,
              (isChecked || isIndeterminate) && c.iconChecked,
              checkedChanged && (isChecked || isIndeterminate) && c.iconShow,
              checkedChanged && !(isChecked || isIndeterminate) && c.iconHide,
            ]}
          />
        </div>
      </>
    );
  }
);

//////////////
//  Toggle  //
//////////////

export const Toggle = React.forwardRef<HTMLInputElement, InputProps>(function Toggle(
  { checked, labelledBy, state = 'enabled', onChange: onChangeProp, ...attrs },
  ref
) {
  const { attrsOverride, isChecked, isDisabled, onChange } = useCheckbox(
    checked,
    labelledBy,
    onChangeProp,
    state
  );

  // @ts-expect-error - This is a runtime check to ensure no one broke the type rules
  if (attrs.indeterminate) delete attrs.indeterminate;

  return (
    <>
      <input
        {...attrs}
        {...attrsOverride}
        className={s9(c.input)}
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
        ref={ref}
      />
      <div aria-hidden="true" className={s9(c.toggle, isChecked && c.toggleChecked)} />
    </>
  );
});

/////////////////////////
//  Default Component  //
/////////////////////////

interface CheckboxUniqueProps extends IndeterminateProp {
  appearance?: 'checkbox';
}
interface ToggleUniqueProps {
  appearance: 'toggle';
}

export type CheckboxProps = SharedProps &
  Omit<InputOwnProps, 'labelledBy'> &
  React.ComponentPropsWithoutRef<'input'> &
  (CheckboxUniqueProps | ToggleUniqueProps);

type ValidationState = 'default' | 'invalid';

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  {
    inlineStyle,
    checked = false,
    children,
    styleExtend = [],
    labelStyleExtend,
    state = 'enabled',
    appearance,
    errorText,
    ...attrs
  }: CheckboxProps,
  ref
) {
  const checkboxId = React.useId();
  const labelId = React.useId();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [validationState, setValidationState] = React.useState<ValidationState>('default');

  return (
    <CheckboxWrapper
      style={inlineStyle}
      styleExtend={styleExtend}
      inputRef={ref || inputRef}
      state={state}
    >
      {appearance === 'toggle' ? (
        <Toggle
          {...attrs}
          checked={checked}
          id={checkboxId}
          labelledBy={labelId}
          state={state}
          ref={ref || inputRef}
        />
      ) : (
        <FauxCheckbox
          {...attrs}
          onChange={event => {
            if (errorText && validationState !== 'default') {
              setValidationState('default');
            }
            if (typeof attrs.onChange === 'function') {
              attrs.onChange(event);
            }
          }}
          onInvalid={event => {
            if (errorText) {
              setValidationState('invalid');
            }

            if (typeof attrs.onInvalid === 'function') {
              attrs.onInvalid(event);
            }
          }}
          checked={checked}
          id={checkboxId}
          labelledBy={labelId}
          state={state}
          ref={ref || inputRef}
        />
      )}
      <CheckboxText styleExtend={labelStyleExtend} id={labelId}>
        {children}
        {errorText && validationState === 'invalid' ? (
          <div className={s9(c.error)}>{errorText}</div>
        ) : null}
      </CheckboxText>
    </CheckboxWrapper>
  );
});

export default Checkbox;

/////////////
//  Utils  //
/////////////

type OverrideAttrs = 'labelledBy';
type CheckboxOverrideAttrNames = 'aria-labelledby' | 'type';
type CheckboxType = { type: 'checkbox' };
type CheckboxOverrideAttrs = Pick<InputBaseProps, CheckboxOverrideAttrNames> & CheckboxType;

function getAttrsOverride({ labelledBy }: Pick<InputProps, OverrideAttrs>) {
  const attrsOverride: CheckboxOverrideAttrs = {
    type: 'checkbox',
  };

  if (labelledBy) attrsOverride['aria-labelledby'] = labelledBy;

  return attrsOverride;
}

function useCheckbox(
  checked: InputProps['checked'],
  labelledBy: InputProps['labelledBy'],
  onChangeProp: InputProps['onChange'],
  state: InputProps['state'],
  setIsIndeterminate?: React.Dispatch<React.SetStateAction<boolean | undefined>>
) {
  const [isChecked, setIsChecked] = React.useState(checked);
  const isDisabled = state === 'disabled';
  const attrsOverride = getAttrsOverride({ labelledBy });

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  function onChange(evt: React.ChangeEvent<HTMLInputElement>) {
    evt.target.checked = !isChecked;
    setIsChecked(evt.target.checked);
    if (setIsIndeterminate) setIsIndeterminate(false);

    if (onChangeProp) onChangeProp(evt);
  }

  return {
    attrsOverride,
    isChecked,
    isDisabled,
    onChange,
  };
}
