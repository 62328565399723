import * as React from 'react';
import s9 from 'style9';

import type {
  StyleExtend,
  InlineStyles,
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithRef,
} from '@haaretz/s-types';

const classNames = s9.create({
  base: {
    border: '0',
    // @ts-expect-error - for old browsers
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    margin: '0 -1px -1px 0',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
    whiteSpace: 'nowrap',
    wordWrap: 'normal',
    opacity: 0,
  },
  focusable: {
    ':active': {
      // @ts-expect-error - for old browsers
      clip: 'auto',
      clipPath: 'none',
      height: 'auto',
      margin: '0',
      overflow: 'visible',
      position: 'static',
      width: 'auto',
      whiteSpace: 'inherit',
      opacity: 1,
    },
    ':focus': {
      // @ts-expect-error - for old browsers
      clip: 'auto',
      clipPath: 'none',
      height: 'auto',
      margin: '0',
      overflow: 'visible',
      position: 'static',
      width: 'auto',
      whiteSpace: 'inherit',
      opacity: 1,
    },
  },
});

export interface VisuallyHiddenOwnProps {
  /** The content to hide inside `<VisuallyHidden>` */
  children: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyVisuallyHidden(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <VisuallyHidden
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * Should the element become visible when focused, e.g., skip links
   */
  isFocusable?: true;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <VisuallyHidden styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export const DEFAULT_ELEMENT = 'span';
type DefaultElement = typeof DEFAULT_ELEMENT;

export type VisuallyHiddenProps<As extends React.ElementType = DefaultElement> =
  PolymorphicPropsWithRef<VisuallyHiddenOwnProps, As>;

/* eslint-disable @typescript-eslint/indent */
const VisuallyHidden: PolymorphicForwardRefExoticComponent<VisuallyHiddenOwnProps, DefaultElement> =
  React.forwardRef(
    /* eslint-enable @typescript-eslint/indent */
    function VisuallyHidden<As extends React.ElementType = DefaultElement>(
      {
        as,
        children = null,
        inlineStyle,
        isFocusable,
        styleExtend = [],
        ...attrs
      }: VisuallyHiddenProps<As>,
      ref: React.ForwardedRef<As>
    ) {
      const Element: React.ElementType = as || DEFAULT_ELEMENT;
      return (
        <Element
          ref={ref}
          className={s9(classNames.base, isFocusable && classNames.focusable, ...styleExtend)}
          style={inlineStyle}
          {...attrs}
        >
          {children}
        </Element>
      );
    }
  );

export default VisuallyHidden;

// This empty Componrnt is only used for Storybook prop inference
export function _VisuallyHidden(_props: VisuallyHiddenOwnProps): React.ReactNode {
  return null;
}
