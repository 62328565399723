'use client';
import color from '@haaretz/l-color.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import * as React from 'react';
import s9 from 'style9';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    '--scale': 0,
    backgroundColor: color('primary600'),
    borderRadius: radius('circle'),
    left: '50%',
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%) scale(var(--scale))',
    transitionDuration: '0',
    // transitionDuration: '0.15s',
    transitionProperty: 'transform, opacity',
    transitionTimingFunction: 'ease-in-out',
    zIndex: zIndex('below'),
  },
  extraSmallSize: {
    padding: space(4),
  },
  smallSize: {
    padding: space(6),
  },
  mediumSize: {
    padding: space(9),
  },
  largeSize: {
    padding: space(12),
  },
  contracting: {
    opacity: 0,
    transitionDuration: '0.15s',
  },
  expanded: {
    '--scale': 1,
    opacity: 0.3,
    transitionDuration: '0.5s',
  },
  pulse: {
    '--scale': 1,
    opacity: 0,
    transitionDuration: '0.5s',
  },
});

export interface RippleProps {
  /** The size of the ripple. */
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  /**
   * Controls the ripple's state and animation.
   *
   * * **`contracted`:** is the off state, when the ripple is not visible.
   * * **`expanded`:** shows the ripple at its full size.
   * * **`contracting`:** animates the ripple from its full size to the off state.
   * * **`pulse`:** animates the ripple from its off state to its full size, but
   *   fade it out in the end.
   *
   */
  expansionState?: 'contracted' | 'contracting' | 'expanded' | 'pulse';
  /**
   * A callback that is executed when the ripple animation is over
   * in the `pulse` and `contracting` states and indicates what
   * should the next `expansionState` be.
   *
   * Can be used to notifying controlling ascendant components that the ripple
   * has finished animating and they can change state if needed
   * (mostly the state that controls the `expansionState` prop).
   */
  onPulseEnd?: (nextState: RippleProps['expansionState']) => void;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyRipple(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Ripple
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Ripple styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

const Ripple = React.forwardRef<HTMLDivElement, RippleProps>(function Ripple(
  { size = 'medium', expansionState = 'contracted', onPulseEnd, inlineStyle, styleExtend = [] },
  ref
) {
  const sizeClass: `${Exclude<RippleProps['size'], undefined>}Size` = `${size}Size`;

  const onTransitionEnd = React.useCallback(() => {
    if (['pulse', 'contracting'].includes(expansionState) && onPulseEnd) {
      onPulseEnd('contracted');
    }
  }, [expansionState, onPulseEnd]);

  return (
    <div
      className={s9(
        c.base,
        expansionState === 'expanded' && c.expanded,
        expansionState === 'contracting' && c.contracting,
        expansionState === 'pulse' && c.pulse,
        c[sizeClass],
        ...styleExtend
      )}
      style={inlineStyle}
      ref={ref}
      onTransitionEnd={onTransitionEnd}
    />
  );
});

export default Ripple;
