'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Ripple from '@haaretz/s-ripple';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { RippleProps } from '@haaretz/s-ripple';
import type {
  StyleExtend,
  InlineStyles,
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithRef,
} from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    '--_size': 'var(--click-area-size-override, var(--size))',
    backgroundColor: 'transparent',
    minHeight: 'var(--_size)',
    minWidth: 'var(--_size)',
    position: 'relative',
    transform: 'scale(1)',
    transitionDuration: '0.15s',
    transitionProperty: 'transform',

    ':focus': { outline: 'none' },
    ':active': { transform: 'scale(0.95)' },
  },
  extraSmallSize: {
    '--size': space(3),
  },
  smallSize: {
    '--size': space(6),
  },
  mediumSize: {
    '--size': space(8),
  },
  largeSize: {
    '--size': space(11),
  },
  disabled: {
    opacity: 0.25,
    ':active': {
      transform: 'none',
    },
  },
});

export interface ClickAreaOwnProps {
  /** The Children to be rendered inside `<ClickArea>` */
  children?: React.ReactNode;
  /** Size for the click area. also controls the the size of the Ripple.  */
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  /** Text that will only be visible to screen readers */
  screenReaderText?: React.ReactNode;
  /** Prevent the ClickArea from emiting a ripple to indicate interactivity */
  disableRipple?: boolean;
  /** Set the size of the ripple */
  rippleSize?: RippleProps['size'];
  /** Directly control the ripple state from the outside */
  rippleExpansionState?: RippleProps['expansionState'];
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyClickArea(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <ClickArea
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <ClickArea styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export const DEFAULT_ELEMENT = 'button' as const;
type DefaultElement = typeof DEFAULT_ELEMENT;
type AllowedElements = 'a' | 'button';

export type ClickAreaProps<As extends React.ElementType = DefaultElement> = PolymorphicPropsWithRef<
  ClickAreaOwnProps,
  As,
  AllowedElements
>;

const ClickArea: PolymorphicForwardRefExoticComponent<
  ClickAreaOwnProps,
  DefaultElement,
  AllowedElements
> = React.forwardRef(function ClickArea<As extends React.ElementType = DefaultElement>(
  {
    as,
    children = null,
    disableRipple,
    inlineStyle,
    onClick: onClickProp,
    onFocus: onFocusProp,
    onBlur: onBlurProp,
    screenReaderText,
    size = 'medium',
    rippleSize,
    styleExtend = [],
    rippleExpansionState,
    ...attrs
  }: ClickAreaProps<As>,
  ref: React.ForwardedRef<As>
) {
  const [rippleState, setRippleState] = React.useState<RippleProps['expansionState']>(
    rippleExpansionState || 'contracted'
  );

  const Element: React.ElementType = as || DEFAULT_ELEMENT;

  const isDisabled =
    isTruthyBooleanish(attrs['aria-disabled' as keyof typeof attrs]) ||
    isTruthyBooleanish(attrs.disabled);

  const sizeClass: `${Exclude<ClickAreaOwnProps['size'], undefined>}Size` = `${size}Size`;

  const onClick = (evt: React.MouseEvent) => {
    if (onClickProp) onClickProp(evt);
    if (!rippleExpansionState) setRippleState('pulse');
  };
  const onFocus = (evt: React.FocusEvent) => {
    if (onFocusProp) onFocusProp(evt);
    if (!rippleExpansionState) setRippleState('expanded');
  };
  const onBlur = (evt: React.FocusEvent) => {
    if (onBlurProp) onBlurProp(evt);
    if (!rippleExpansionState) setRippleState('contracting');
  };

  return (
    <Element
      {...attrs}
      className={s9(c.base, c[sizeClass], isDisabled && c.disabled, ...styleExtend)}
      style={inlineStyle}
      ref={ref}
      onClick={isDisabled ? undefined : onClick}
      onFocus={isDisabled ? undefined : onFocus}
      onBlur={isDisabled ? undefined : onBlur}
    >
      {disableRipple || isDisabled ? null : (
        <Ripple size={rippleSize} expansionState={rippleState} onPulseEnd={setRippleState} />
      )}
      {screenReaderText ? <VisuallyHidden>{screenReaderText}</VisuallyHidden> : null}
      {children}
    </Element>
  );
});

export default ClickArea;
// This empty Component is only used for Storybook prop inference
export function _ClickArea(_props: ClickAreaOwnProps): React.ReactNode {
  return null;
}

///////////////
//  HELPERS  //
///////////////

function isTruthyBooleanish(value: unknown): boolean {
  return value === true || value === 'true';
}
