/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Icon from '@haaretz/s-icon';
import parseButtonProps from '@haaretz/s-parse-button-props';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { IconName } from '@haaretz/l-icon-sprite/types';
import type { ButtonA11yProps } from '@haaretz/s-parse-button-props';
import type {
  StyleExtend,
  InlineStyles,
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithRef,
} from '@haaretz/s-types';

const borderWidth = '1px';
const colorTransitionTime = '0.2s';
const outlineTransitionTime = '0.1s';
const c = s9.create({
  base: {
    alignItems: 'center',
    borderStartStartRadius: radius('small'),
    borderStartEndRadius: radius('small'),
    borderEndStartRadius: radius('small'),
    borderEndEndRadius: radius('small'),
    columnGap: space(1),
    display: 'inline-flex',
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    fontWeight: 700,
    outlineStyle: 'solid',
    outlineOffset: 0,
    outlineWidth: 0,
    overflow: 'hidden',
    position: 'relative',
    transitionProperty: 'background-color, color, border-color, outline-offset, outline-width',
    transitionDuration: `${
      // background-color
      colorTransitionTime
    }, ${
      // color
      colorTransitionTime
    }, ${
      // border-color
      colorTransitionTime
    }, ${
      // outline-offset
      outlineTransitionTime
    }, ${
      // outline-width
      outlineTransitionTime
    }`,
    transitionTimingFunction: 'ease-in-out',
    ...merge(
      {
        ':focus-visible': {
          outlineWidth: '2px',
          outlineOffset: '6px',
        },
        ':active': {
          transform: 'scale(0.95)',
        },
      },
      {
        ...typesetter(0),
        ...border({
          width: borderWidth,
          color: 'var(--btn-brdr-c)',
          style: 'solid',
          spacing: 1,
          side: 'block',
        }),
        ...border({
          width: borderWidth,
          color: 'var(--btn-brdr-c)',
          style: 'solid',
          spacing: 4,
          side: 'inline',
        }),
      },
      mq({ from: 'xxl', value: { ...typesetter(-1) } })
    ),
  },
  disabledState: {
    opacity: 0.25,
    ':hover': {
      transform: 'none',
    },
    ':active': {
      transform: 'none',
    },
  },
  busyState: {
    ':hover': {
      transform: 'none',
    },
    ':active': {
      transform: 'none',
    },
    ':before': {
      animationDuration: '1s',
      animationDirection: 'alternate',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationName: s9.keyframes({
        from: { transform: 'scaleX(0)' },
        to: { transform: 'scaleX(1)' },
      }),
      backgroundColor: 'var(--btn-bsy-bgc)',
      content: '""',
      display: 'block',
      pointerEvents: 'none',
      position: 'absolute',
      transformOrigin: fork({
        default: 'right',
        hdc: 'left',
      }),

      // Stretch the loading animation to extend
      // into area occupied by the border
      height: `calc(100% + ${borderWidth} * 2)`,
      left: `-${borderWidth}`,
      top: `-${borderWidth}`,
      width: `calc(100% + ${borderWidth} * 2)`,
    },
  },
  primaryPriority: {
    '--btn-brdr-c': 'transparent',
    '--btn-bsy-bgc': 'var(--btn-bsy-prmry-bgc)',
    backgroundColor: 'var(--btn-prmry-bgc)',
    color: 'var(--btn-prmry-c)',
    ':hover': {
      backgroundColor: 'var(--btn-prmry-hvr-bgc)',
      color: 'var(--btn-prmry-hvr-c, var(--btn-prmry-c))',
    },
    ':focus': {
      backgroundColor: 'var(--btn-prmry-fcs-bgc, var(--btn-prmry-hvr-bgc))',
      color: 'var(--btn-prmry-fcs-c, var(--btn-prmry-c))',
    },
    ':active': {
      backgroundColor: 'var(--btn-prmry-actv-bgc, var(--btn-prmry-hvr-bgc))',
      color: 'var(--btn-prmry-actv-c, var(--btn-prmry-c))',
    },
  },
  secondaryPriority: {
    '--btn-brdr-c': 'var(--btn-brdr-scndry-c)',
    '--btn-bsy-bgc': 'var(--btn-bsy-scndry-bgc)',
    backgroundColor: 'var(--btn-scndry-bgc)',
    color: 'var(--btn-scndry-c)',
    ':hover': {
      '--btn-brdr-c': 'var(--btn-brdr-scndry-hvr-c, var(--btn-brdr-scndry-c))',
      backgroundColor: 'var(--btn-scndry-hvr-bgc)',
      color: 'var(--btn-scndry-hvr-c, var(--btn-scndry-c))',
    },
    ':focus': {
      '--btn-brdr-c': 'var(--btn-brdr-scndry-fcs-c, var(--btn-brdr-scndry-hvr-c))',
      backgroundColor: 'var(--btn-scndry-fcs-bgc, var(--btn-scndry-hvr-bgc))',
      color: 'var(--btn-scndry-fcs-c, var(--btn-scndry-c))',
    },
    ':active': {
      '--btn-brdr-c': 'var(--btn-brdr-scndry-actv-c, var(--btn-brdr-scndry-hvr-c))',
      backgroundColor: 'var(--btn-scndry-actv-bgc, var(--btn-scndry-hvr-bgc))',
      color: 'var(--btn-scndry-actv-c, var(--btn-scndry-c))',
    },
  },
  tertiaryPriority: {
    // No border on text buttons
    '--btn-brdr-c': 'transparent',
    '--btn-bsy-bgc': 'var(--btn-bsy-trtry-bgc)',
    backgroundColor: 'var(--btn-trtry-bgc)',
    color: 'var(--btn-trtry-c)',
    ':hover': {
      backgroundColor: 'var(--btn-trtry-hvr-bgc)',
      color: 'var(--btn-trtry-hvr-c, var(--btn-trtry-c))',
    },
    ':focus': {
      backgroundColor: 'var(--btn-trtry-fcs-bgc, var(--btn-trtry-hvr-bgc))',
      color: 'var(--btn-trtry-fcs-c, var(--btn-trtry-c))',
    },
    ':focus-visible': {
      // Remove outline on text buttons
      outlineWidth: '0',
      outlineOffset: '0',
    },
    ':active': {
      backgroundColor: 'var(--btn-trtry-actv-bgc, var(--btn-trtry-hvr-bgc))',
      color: 'var(--btn-trtry-actv-c, var(--btn-trtry-c))',
    },
  },
  smallSize: {
    ...merge(
      mq({
        until: 'xl',
        value: {
          paddingInlineStart: space(3),
          paddingInlineEnd: space(3),
          paddingBottom: `calc(${space(1)} - ${borderWidth})`,
          paddingTop: `calc(${space(1)} - ${borderWidth})`,
          ...typesetter(-2, { lines: 4 }),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  largeSize: {
    ...merge(
      {
        paddingBottom: `calc(${space(2)} - ${borderWidth})`,
        paddingTop: `calc(${space(2)} - ${borderWidth})`,
        paddingInlineStart: space(5),
        paddingInlineEnd: space(5),

        ...typesetter(2, { lines: 7 }),
      },
      mq({
        from: 'xl',
        value: {
          paddingInlineStart: space(6),
          paddingInlineEnd: space(6),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(2, { lines: 8 }),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(1),
        },
      })
    ),
  },
  brandVariant: {
    // Primary button colors
    '--btn-bsy-prmry-bgc': color('primary1100'),
    '--btn-prmry-bgc': color('primary1000'),
    '--btn-prmry-c': color('bodyTextNegative'),
    '--btn-prmry-hvr-bgc': color('primary1200'),
    // '--btn-prmry-hvr-c': color('bodyTextNegative'),
    // '--btn-prmry-fcs-bgc': color('primary1200'),
    // '--btn-prmry-fcs-c': color('bodyTextNegative'),
    // '--btn-prmry-actv-bgc': color('primary1200'),
    // '--btn-prmry-actv-c': color('bodyTextNegative'),

    // Secondary button colors
    '--btn-bsy-scndry-bgc': color('primary400'),
    // '--btn-scndry-bgc': color(),
    '--btn-scndry-c': color('primary1000'),
    '--btn-brdr-scndry-c': color('primary1000', { opacity: 0.2 }),
    '--btn-scndry-hvr-bgc': fork({ default: color('primary200'), tm: color('primary100') }),
    // '--btn-scndry-hvr-c': color('primary1000'),
    '--btn-brdr-scndry-hvr-c': color('primary1000'),
    // '--btn-scndry-fcs-bgc': fork({ default: color('primary200'), tm: color('primary100'), }),
    // '--btn-scndry-fcs-c': color('primary1000'),
    // '--btn-brdr-scndry-fcs-c': fork({ default: color('primary200'), tm: color('primary100'), }),
    // '--btn-scndry-actv-bgc': fork({ default: color('primary200'), tm: color('primary100'), }),
    // '--btn-scndry-actv-c': color('primary1000'),
    // '--btn-brdr-scndry-actv-c': fork({ default: color('primary200'), tm: color('primary100'), }),

    // Tertiary button colors
    '--btn-bsy-trtry-bgc': color('primary400'),
    // '--btn-trtry-bgc': color(),
    '--btn-trtry-c': color('primary1000'),
    '--btn-trtry-hvr-bgc': fork({ default: color('primary200'), tm: color('primary100') }),
    // '--btn-trtry-hvr-c': color('primary1000'),
    // '--btn-trtry-fcs-bgc': fork({ default: color('primary200'), tm: color('primary100'), }),
    // '--btn-trtry-fcs-c': color('primary1000'),
    // '--btn-trtry-actv-bgc': fork({ default: color('primary200'), tm: color('primary100'), }),
    // '--btn-trtry-actv-c': color('primary1000'),
    outlineColor: color('primary1000'),
  },
  neutralVariant: {
    // Primary button colors
    '--btn-bsy-prmry-bgc': color('neutral1100'),
    '--btn-prmry-bgc': color('neutral1000'),
    '--btn-prmry-c': color('bodyTextNegative'),
    '--btn-prmry-hvr-bgc': color('neutral1200'),
    // '--btn-prmry-hvr-c': color('bodyTextNegative'),
    // '--btn-prmry-fcs-bgc': color('neutral1200'),
    // '--btn-prmry-fcs-c': color('bodyTextNegative'),
    // '--btn-prmry-actv-bgc': color('neutral1200'),
    // '--btn-prmry-actv-c': color('bodyTextNegative'),

    // Secondary button colors
    '--btn-bsy-scndry-bgc': color('neutral400'),
    // '--btn-scndry-bgc': color(),
    '--btn-scndry-c': color('bodyText'),
    '--btn-brdr-scndry-c': color('bodyText', { opacity: 0.2 }),
    '--btn-scndry-hvr-bgc': color('neutral300'),
    // '--btn-scndry-hvr-c': color('bodyText'),
    '--btn-brdr-scndry-hvr-c': color('bodyText'),
    // '--btn-scndry-fcs-bgc': color('neutral300'),
    // '--btn-scndry-fcs-c': color('bodyText'),
    // '--btn-brdr-scndry-fcs-c': color('bodyText'),
    // '--btn-scndry-actv-bgc': color('neutral300'),
    // '--btn-scndry-actv-c': color('bodyText'),
    // '--btn-brdr-scndry-actv-c': color('bodyText'),

    // Tertiary button colors
    '--btn-bsy-trtry-bgc': color('neutral400'),
    // '--btn-trtry-bgc': color(),
    '--btn-trtry-c': color('bodyText'),
    '--btn-trtry-hvr-bgc': color('neutral300'),
    // '--btn-trtry-hvr-c': color('bodyText'),
    '--btn-brdr-trtry-hvr-c': color('bodyText'),
    // '--btn-trtry-fcs-bgc': color('neutral300'),
    // '--btn-trtry-fcs-c': color('bodyText'),
    // '--btn-brdr-trtry-fcs-c': color('bodyText'),
    // '--btn-trtry-actv-bgc': color('neutral300'),
    // '--btn-trtry-actv-c': color('bodyText'),
    // '--btn-brdr-trtry-actv-c': color('bodyText'),
    outlineColor: color('bodyText'),
  },
  dangerVariant: {
    // Primary button colors
    '--btn-bsy-prmry-bgc': color('secondary1000'),
    '--btn-prmry-bgc': color('secondary900'),
    '--btn-prmry-c': color('bodyTextNegative'),
    '--btn-prmry-hvr-bgc': color('secondary1100'),
    // '--btn-prmry-hvr-c': color('bodyTextNegative'),
    // '--btn-prmry-fcs-bgc': color('secondary1100'),
    // '--btn-prmry-fcs-c': color('bodyTextNegative'),
    // '--btn-prmry-actv-bgc': color('secondary1100'),
    // '--btn-prmry-actv-c': color('bodyTextNegative'),

    // Secondary button colors
    '--btn-bsy-scndry-bgc': color('secondary300'),
    // '--btn-scndry-bgc': color(),
    '--btn-scndry-c': color('secondary900'),
    '--btn-brdr-scndry-c': color('secondary900', { opacity: 0.2 }),
    '--btn-scndry-hvr-bgc': color('secondary100'),
    // '--btn-scndry-hvr-c': color('secondary900'),
    '--btn-brdr-scndry-hvr-c': color('secondary900'),
    // '--btn-scndry-fcs-bgquaternary100': color('secondary100'),
    // '--btn-scndry-fcs-c': color('secondary900'),
    // '--btn-brdr-scndry-fcs-c': color('secondary900'),
    // '--btn-scndquaternary100y-actv-bgc': color('secondary100'),
    // '--btn-scndry-actv-c': color('secondary900'),
    // '--btn-brdr-scndry-actv-c': color('secondary900'),

    // Tertiary button colors
    '--btn-bsy-trtry-bgc': color('neutral400'),
    // '--btn-trtry-bgc': color(),
    '--btn-trtry-c': color('secondary900'),
    '--btn-trtry-hvr-bgc': color('secondary100'),
    // '--btn-trtry-hvr-c': color('secondary900'),
    '--btn-brdr-trtry-hvr-c': color('secondary900'),
    // '--btn-trtry-fcs-bgc': color('secondary100'),
    // '--btn-trtry-fcs-c': color('secondary900'),
    // '--btn-brdr-trtry-fcs-c': color('secondary900'),
    // '--btn-trtry-actv-bgc': color('secondary100'),
    // '--btn-trtry-actv-c': color('secondary900'),
    // '--btn-brdr-trtry-actv-c': color('secondary900'),
    outlineColor: color('secondary900'),
  },
  successVariant: {
    // Primary button colors
    '--btn-bsy-prmry-bgc': color('quaternary800'),
    '--btn-prmry-bgc': color('quaternary700'),
    '--btn-prmry-c': color('bodyTextNegative'),
    '--btn-prmry-hvr-bgc': color('quaternary900'),
    // '--btn-prmry-hvr-c': color('bodyTextNegative'),
    // '--btn-prmry-fcs-bgc': color('quaternary900'),
    // '--btn-prmry-fcs-c': color('bodyTextNegative'),
    // '--btn-prmry-actv-bgc': color('quaternary900'),
    // '--btn-prmry-actv-c': color('bodyTextNegative'),

    // Secondary button colors
    '--btn-bsy-scndry-bgc': color('quaternary300'),
    // '--btn-scndry-bgc': color(),
    '--btn-scndry-c': color('quaternary700'),
    '--btn-brdr-scndry-c': color('quaternary700', { opacity: 0.2 }),
    '--btn-scndry-hvr-bgc': color('quaternary100'),
    // '--btn-scndry-hvr-c': color('quaternary700'),
    '--btn-brdr-scndry-hvr-c': color('quaternary700'),
    // '--btn-scndry-fcs-bgquaternary100': color('quaternary100'),
    // '--btn-scndry-fcs-c': color('quaternary700'),
    // '--btn-brdr-scndry-fcs-c': color('quaternary700'),
    // '--btn-scndquaternary100y-actv-bgc': color('quaternary100'),
    // '--btn-scndry-actv-c': color('quaternary700'),
    // '--btn-brdr-scndry-actv-c': color('quaternary700'),

    // Tertiary button colors
    '--btn-bsy-trtry-bgc': color('quaternary300'),
    // '--btn-trtry-bgc': color(),
    '--btn-trtry-c': color('quaternary700'),
    '--btn-trtry-hvr-bgc': color('quaternary100'),
    // '--btn-trtry-hvr-c': color('quaternary700'),
    '--btn-brdr-trtry-hvr-c': color('quaternary700'),
    // '--btn-trtry-fcs-bgc': color('quaternary100'),
    // '--btn-trtry-fcs-c': color('quaternary700'),
    // '--btn-brdr-trtry-fcs-c': color('quaternary700'),
    // '--btn-trtry-actv-bgc': color('quaternary100'),
    // '--btn-trtry-actv-c': color('quaternary700'),
    // '--btn-brdr-trtry-actv-c': color('quaternary700'),
    outlineColor: color('quaternary700'),
  },
  salesVariant: {
    // Primary button colors
    '--btn-bsy-prmry-bgc': color('sales700'),
    '--btn-prmry-bgc': color('salesBtn'),
    '--btn-prmry-c': color('textDarkAllModes'),
    '--btn-prmry-hvr-bgc': color('sales600'),
    // '--btn-prmry-hvr-c': color('bodyText'),
    // '--btn-prmry-fcs-bgc': color('sales600'),
    // '--btn-prmry-fcs-c': color('bodyText'),
    // '--btn-prmry-actv-bgc': color('sales600'),
    // '--btn-prmry-actv-c': color('bodyText'),

    // Secondary button colors
    '--btn-bsy-scndry-bgc': color('sales600'),
    // '--btn-scndry-bgc': color(),
    '--btn-scndry-c': color('sales600'),
    '--btn-brdr-scndry-c': color('sales600', { opacity: 0.2 }),
    '--btn-scndry-hvr-bgc': color('sales100'),
    // '--btn-scndry-hvr-c': color('sales600'),
    '--btn-brdr-scndry-hvr-c': color('sales600'),
    // '--btn-scndry-fcs-bgquaternary100': color('sales100'),
    // '--btn-scndry-fcs-c': color('sales600'),
    // '--btn-brdr-scndry-fcs-c': color('sales600'),
    // '--btn-scndquaternary100y-actv-bgc': color('sales100'),
    // '--btn-scndry-actv-c': color('sales600'),
    // '--btn-brdr-scndry-actv-c': color('sales600'),

    // Tertiary button colors
    '--btn-bsy-trtry-bgc': color('sales600'),
    '--btn-trtry-bgc': 'transparent',
    '--btn-trtry-c': color('sales600'),
    '--btn-trtry-hvr-bgc': color('sales100'),
    // '--btn-trtry-hvr-c': color('sales600'),
    '--btn-brdr-trtry-hvr-c': color('sales600'),
    // '--btn-trtry-fcs-bgc': color('sales100'),
    // '--btn-trtry-fcs-c': color('sales600'),
    // '--btn-brdr-trtry-fcs-c': color('sales600'),
    // '--btn-trtry-actv-bgc': color('sales100'),
    // '--btn-trtry-actv-c': color('sales600'),
    // '--btn-brdr-trtry-actv-c': color('sales600'),
    outlineColor: color('sales600'),

    borderRadius: radius('pill'),
    textTransform: 'uppercase',
  },
  stretch: {
    maxWidth: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  topSharp: {
    borderTopLeftRadius: radius('sharp'),
    borderTopRightRadius: radius('sharp'),
  },
  bottomSharp: {
    borderBottomLeftRadius: radius('sharp'),
    borderBottomRightRadius: radius('sharp'),
  },
  startSharp: {
    borderStartStartRadius: radius('sharp'),
    borderEndStartRadius: radius('sharp'),
  },
  endSharp: {
    borderStartEndRadius: radius('sharp'),
    borderEndEndRadius: radius('sharp'),
  },
  allSharp: {
    borderStartStartRadius: radius('sharp'),
    borderStartEndRadius: radius('sharp'),
    borderEndStartRadius: radius('sharp'),
    borderEndEndRadius: radius('sharp'),
  },

  childrenWrapper: {
    // Place children above "busy" indicator
    position: 'relative',
    // Prevent line breaks
    whiteSpace: 'nowrap',
  },
  icon: {
    lineHeight: 1,
    fontSize: space(6),

    ...merge({
      ...mq({ until: 'm', value: { fontSize: space(4) } }),
      ...mq({ from: 'xl', value: { fontSize: space(7) } }),
    }),
  },
  iconLarge: {
    fontSize: space(7),

    ...merge({
      ...mq({ from: 'xl', value: { fontSize: space(8) } }),
    }),
  },
  iconSmall: {
    fontSize: space(4),

    ...merge({
      ...mq({ from: 'xl', value: { fontSize: space(5) } }),
    }),
  },
  startIcon: {
    marginInlineStart: space(-2),
  },
  endIcon: {
    marginInlineEnd: space(-2),
  },
});

export type ButtonState =
  | {
      state?: Exclude<ButtonA11yProps['state'], 'busy'>;
      busyNotice?: undefined;
    }
  | {
      state: 'busy';
      /** The notification read by screen readers to notify the user
       * that something is happening, e.g., "Loading more articles".
       * **Required when `state` is set to `busy`.**
       */
      busyNotice: string;
    };
export interface ButtonStateLessOwnProps extends Omit<ButtonA11yProps, 'as' | 'style' | 'state'> {
  /** The Children to be rendered inside `<Button>` */
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * Signals the button's priority within the general UI composition
   * in which it is used and in relation to other buttons related to it.<br />
   * `primary` is an opaque button with a solid background, `secondary` is an
   * outlined button with a solid stroke, and `tertiary` is a textual-only button.
   *
   * @defaultValue 'primary'
   */
  priority?: 'primary' | 'secondary' | 'tertiary';
  /**
   * Sets the color of the button and indicates intent
   *
   * @defaultValue 'brand'
   */
  variant?: 'brand' | 'neutral' | 'danger' | 'success' | 'sales';
  /**
   * Sets the button's typography and inner-spacing (padding).
   *
   * @defaultValue 'regular'
   */
  size?: 'large' | 'regular' | 'small';
  /**
   * Expand to fill the full width the Button's parent container
   *
   * @defaultValue false
   */
  stretch?: boolean;
  /**
   * Sets sharp corners (without border-radius) on two or more corners
   *
   * @defaultValue undefined
   */
  sharp?: 'top' | 'bottom' | 'start' | 'end' | 'all';
  /**
   * An icon component to place at the beginning of the input.
   */
  startIcon?: IconName | React.ReactElement | null;
  /**
   * An icon component to place at the end of the input.
   */
  endIcon?: IconName | React.ReactElement | null;
  className?: never;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  startIconStyleExtend?: StyleExtend;

  endIconStyleExtend?: StyleExtend;

  contentStyleExtend?: StyleExtend;
  autofocus?: 'true' | 'false';
}

type ButtonOwnProps = ButtonStateLessOwnProps & ButtonState;

export const DEFAULT_ELEMENT = 'button';
type DefaultElement = typeof DEFAULT_ELEMENT;
type AllowedElements = 'a' | 'button';

/* eslint-disable @typescript-eslint/indent */
export type ButtonProps<As extends React.ElementType = DefaultElement> = PolymorphicPropsWithRef<
  ButtonOwnProps,
  As,
  AllowedElements
>;
/* eslint-enable @typescript-eslint/indent */

/* eslint-disable @typescript-eslint/indent */
const Button: PolymorphicForwardRefExoticComponent<
  ButtonOwnProps,
  DefaultElement,
  AllowedElements
> = React.forwardRef(
  /* eslint-enable @typescript-eslint/indent */
  function Button<As extends React.ElementType = DefaultElement>(
    {
      as,
      busyNotice,
      children = null,
      inlineStyle,
      isPressed,
      priority = 'primary',
      sharp,
      size = 'regular',
      state = 'auto',
      stretch = false,
      styleExtend = [],
      type = 'button',
      variant = 'brand',
      startIcon = null,
      endIcon = null,
      endIconStyleExtend = [],
      startIconStyleExtend = [],
      contentStyleExtend = [],
      ...attrs
    }: ButtonProps<As>,
    ref: React.ForwardedRef<As>
  ) {
    const Element: React.ElementType = as || DEFAULT_ELEMENT;
    const isButton = Element === 'button';
    if (!isButton && !('href' in attrs)) {
      throw new TypeError(
        '<Button /> may only render a "button" or an "a" element.\nYou passed something else to the "as" prop'
      );
    }

    const hasState = state !== 'auto';
    const hasSize = size !== 'regular';
    const a11yAttrs = isButton
      ? parseButtonProps({ as: 'button', state, isPressed, type, style: inlineStyle })
      : parseButtonProps({ as: 'a', state, type, isPressed, style: inlineStyle });

    const priorityClass: `${ButtonOwnProps['priority']}Priority` = `${priority}Priority`;
    const variantClass: `${ButtonOwnProps['variant']}Variant` = `${variant}Variant`;

    const screenReaderText =
      state === 'busy' && typeof busyNotice === 'string' ? (
        <VisuallyHidden>{busyNotice}</VisuallyHidden>
      ) : null;

    return (
      <Element
        ref={ref}
        {...attrs}
        {...a11yAttrs}
        className={s9(
          c.base,
          c[priorityClass],
          c[variantClass],
          hasSize && c[`${size}Size`],
          hasState && c[`${state}State`],
          stretch && c.stretch,
          sharp && c[`${sharp}Sharp`],
          ...styleExtend
        )}
      >
        {typeof startIcon === 'string' ? (
          <Icon
            icon={startIcon}
            styleExtend={[
              c.icon,
              c.startIcon,
              size === 'large' && c.iconLarge,
              size === 'small' && c.iconSmall,
              ...startIconStyleExtend,
            ]}
          />
        ) : (
          startIcon
        )}
        <span className={s9(c.childrenWrapper, ...contentStyleExtend)}>{children}</span>
        {typeof endIcon === 'string' ? (
          <Icon
            icon={endIcon}
            styleExtend={[
              c.icon,
              c.endIcon,
              size === 'large' && c.iconLarge,
              size === 'small' && c.iconSmall,
              ...endIconStyleExtend,
            ]}
          />
        ) : (
          endIcon
        )}
        {screenReaderText}
      </Element>
    );
  }
);

export default Button;

// This empty Component is only used for Storybook prop inference
export function _Button(_props: ButtonOwnProps): React.ReactNode {
  return null;
}
